@import './_mixins'
@import './_play'
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Orbitron:wght@400..900&family=Rubik:ital,wght@0,300..900;1,300..900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap')
*
  box-sizing: border-box
  outline: none

html,
body
  height: 100%
  overflow-x: hidden

body 
  position: relative
  margin: 0
  padding-bottom: 50px
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: var(--body--text-color)
  background: var(--mainBg-radial--background-color)
  font-size: $main-text-size
  scrollbar-color: dark

body::-webkit-scrollbar-track
  background: var(--scrollbar--background-color)

body::-webkit-scrollbar-thumb 
  background-color: var(--scrollbar-thumb--background-color)   /* color of the scroll thumb */
  border-radius: 20px       /* roundness of the scroll thumb */
  border: 3px solid var(--scrollbar--border-color)  /* creates padding around scroll thumb */

code 
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

a 
  color: var(--link-text-color)

a:hover
  color: var(--link-text-color)
  //text-shadow: 0 0 5px #FF0000, 0 0 8px #0000FF
  text-shadow: 0 0 25px black
  opacity: 0.7

.vert-hor-center
    margin: 0
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

//card 
.card-body
    color: var(--cardBody--text-color)
    background: var(--cardBody--background-color)
//end card body

// login
#login .card-header, .login-box .card-header, .register-box .card-header
    background: var(--carHead--background-color)
    border: 0px
    color: black
    font-weight: bold

.login-box .card
    width: 360px
    border: solid 2px var(--second-theme--color)
// end login

// register

#register .card-header 
    background-color: var(--bg-red)

.register-box 
    height: 130vh
    position: relative
    width: 100%

.register-box .card 
    margin: 0
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 360px
    border: none
  
.password-eye
  position: absolute
  top: 65%
  right: 5px
  color: var(--text-dark)
  z-index: 9

// end register

.categoryIcons 
  width: 135px
  min-height: 135px
// end home

//top navigation
.offcanvas-header
  background: var(--main-gradient--color)
.offcanvas-body
  border-right: var(--second-theme--color) 2px solid
.offcanvas
  .text-start
    color: white

hr
  border-bottom: 2px solid var(--hr--color)
  
.navbar
  background-color: var(--bg-dark)

.nav-link
  color: var(--nav-link--text-color)
  font-size: 14px

.navbar-brand
  color: var(--nav-link--text-color)

.navbar-brand:focus, .navbar-brand:hover
  color: var(--nav-link--text-color)

.navbar-toggler-icon 
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")

.navbar-toggler 
  border-color: var(--light-border-color)
  margin-top: 8px

.navbar .topNav-container
  //justify-content: initial

.navbar-toggler:focus
  outline-style: none
  box-shadow: none

.offcanvas
  background-color: var(--bg-dark)

.navbar-text
  color: var(--nav-bar--text-color)
  font-size: 10px

//end top navigation

// add credits
.boxContainer
  //background: var(--style-navybue--background-color)
  border: solid 1px var(--light-border-color)
  background: var(--boxContainer--background-color)
  //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='12' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%239C92AC' fill-opacity='0.25' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"), linear-gradient(to right top, #343a40, #2b2c31, #211f22, #151314, #000000)

.boxContainer .carousel
  min-height: 200px

.boxContainer .carousel a
  position: relative
  margin: auto
  text-decoration: none
  opacity: 1

.boxContainer .carousel button
  
.boxContainer small
  color: var(--nav-bar--text-color)

// end add credits

// play
.iframeWrapper
  position: relative
  padding-bottom: 56.25% //16:9 
  padding-top: 25px
  height: 0
  min-height: 385px

.iframeWrapper iframe
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.style-draw 
    color: var(--style-draw--text-color)
    //color: #282828

.style-cancel 
  color: var(--style-cancel--text-color)
  //color: #fff

.style-meron 
  color: var(--style-meron--text-color)
  //color: #ead6d6

.style-wala 
  color:  var(--style-wala--text-color)
  //color: #e0e0ec

.text-wala
  color: var(--style-wala--text-color)

.button-wala
  //background-color: var(--bg-blue) !important
  background: linear-gradient(0deg, var(--bg-blue) 40%, white 110%) !important
  border: var(--bg-blue) !important

.button-wala, .button-wala:hover, .button-wala:active, 
.button-wala:focus-visible
  background: linear-gradient(0deg, var(--style-navybue--background-color) 40%, var(--bg-blue) 110%) !important
  border: var(--bg-blue) !important

.button-wala:hover
  -webkit-filter: drop-shadow(3px 3px 10px var(--bg-blue))
  filter: drop-shadow(3px 3px 10px var(--bg-blue))
// end play

// account
.account-password-eye
  position: absolute
  top: 65%
  color: #000
  right: 10px
//end account

// pagination
.pagination
  padding: 0
  background: var(--pagination-main--background-color)
  border-radius: 5px
  display: flex
  gap: 8px

.pagination .page-item
  padding: 2px
  border-radius: 5px
  border: none
  outline-offset: 4px
  background: var(--pagination-pageitem--background-color)
  border: 1px solid var(--pagination-pageitem--border-color)
  padding: 0px 5px
  height: 30px

.pagination .page-link
  border: none
  color: var(--pagination-pagelink--text-color)
  border-radius: 10px
  background: none

.pagination .page-link:focus  
  box-shadow: none

.pagination .active
  font-weight: 500
  color: var(--pagination-pagelink--text-color-active)
  padding: 0
  border-radius: 50px
  background-color: var(--second-theme--color)
  border: 1px solid var(--pagination-pagelink--text-color-active)

  span
    display: inline-flex
    width: 30px
    height: 30px
    align-items: center
    justify-content: center
    color: var(--text-dark)
    background: none

//end pagination

// modal
.modal-content
  background: var(--modalBody--background-color)
  border: solid 1px var(--light-border-color)

.modal-content div
  border: none

.modal-content .h5
  color: var(--textH5--color)

.modal-header
  background: var(--modalHeader--background-color)
  
// end modal

//end toast

// footer
.footer
  z-index: -1
// end footer

/**** ADMIN *****/
.search-close-button
  cursor: pointer

.search-icon 
  margin-top: -5px
  margin-left: -35px

.search-icon-agent
  position: absolute
  top: 35%
  right: 5px

//admin search


//admin global
.bg-admin-header
  background: var(--main-gradient--color)
  color: var(--text-dark)

table tr td 
  vertical-align: middle

//end admin global

//AGENT
.react-datepicker__close-icon 
  margin-top: 2px
  color: #aaa !important

.react-datepicker__close-icon::after
  background-color: #aaa !important

.agentHomelinks > div
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

//END AGENT

//global
.imageWrapper
  height: 300px
  display: flex

.imageWrapper img 
  max-width: 300px
  max-height: 250px

.gameImageWrapper img
  max-width: 300px
  max-height: 300px

.imageWrapper a, .gameImageWrapper a
  text-decoration: none

.imageCardLabel
  text-decoration: none

.blinkText
  animation: animate 0.5s linear infinite  

@keyframes animate
  0%
      opacity: 0
  50%
      opacity: 0.7
  100% 
      opacity: 0

.highlightText
  background: var(--dark-theme--color)

//.animate-show-text
//  animation: fadeIn 5s

//@keyframes fadeIn
 // 0%
 //   opacity: 0
 // 100%
 //   opacity: 1

//END GLOBAL
.codeContainer input
 color: black

.codeContainer input::-webkit-outer-spin-button, .codeContainer input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

.codeContainer input[type=number]
  -moz-appearance: textfield

input[name="newpassword"],
input[name="verifypassword"],
input[name="email"],
input[name="username"],
input[name="mobilenumber"],
input[name="password"], 
input[name="verifyPassword"], 
input[name="amount"],
.agentAccountForm form input,
.operatorForm form input,
.operatorMatchViewForm form input,
.addGame input,
input[name="searchParam"],
.viewGameForm input,
.modal-body input,
input[name="gcashName"],
input[name="gcash"],
input[name="amount"],
#login input[name="email"],
#register input[name="email"],
#register input[name="name"],
textarea,
.form-control,
#admin-category-view input
  color: var(--inputtext--text-color)
  background-color: var(--inputtext--background-color)
  border: 1px solid var(--inputtext--background-color)

  &:focus
    background-color: var(--inputtext--background-color)
    color: var(--inputtext--text-color)
    border: 1px solid var(--inputtext--background-color)
    box-shadow: none

  &:active
    border: 1px solid var(--inputtext--background-color)

input[name="amount"]
  border: 1px solid var(--second-theme--color)
  
.search-icon + svg
  right: 12px
  position: relative

.landscape-trend .table-responsive
  border: 1px solid var(--table--border-color)
  box-shadow: none
  padding: 5px

.play-card .card-body
  border: 2px solid var(--cardBody--border-color)
  background-color: var(--cardBody--background-color)

.modal-title 
  color: var(--text-dark)

#searchField
  color: #000000
  background-color: #FFFFFF

  &:focus
    background-color: #FFFFFF
    color: #000000

@import './_button'
@import './_table'
@import './_utils'