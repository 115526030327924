//button override
.btn {
    border-radius: 10px;
}
.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus-visible {
    background: var(--primary-btn--background-color);
    border: var(--main-theme--color) !important;
    color: var(--primary-btn--text-color);
    font-weight: bold;
    text-transform: uppercase;
}
.btn-outline-primary {
    border-color: var(--main-theme--color) !important;
    color: var(--main-theme--color) !important;
}
.btn-outline-primary:hover {
    color: var(--outline-btn--text-color) !important;
    background: var(--dark-theme--color) !important;
}
.btn-outline-primary-ggwp {
    border-color: var(--bg-blue) !important;
    color: var(--bg-blue) !important;
}
.btn-outline-primary-ggwp:hover {
    color: var(--outline-btn--text-color-hover) !important;
    background: var(--bg-blue) !important;
}
.btn-primary:hover {
    -webkit-filter: drop-shadow(3px 3px 10px var(--bg-shadow));
    filter: drop-shadow(3px 3px 10px var(--dark-theme--color));
    background-color: var(--primary-btn--background-color-hover);
}
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus-visible {
    background: var(--secondary-btn--background-color);
    box-shadow: 0 2px 4px 0 var(--secondary-btn--shadow-color);
    border: solid 1px var(--secondary-btn--border-color);
    padding: 8px 18px;
    border-radius: 6px;
    color: var(--secondary-btn--text-color);
}

.btn-secondary:hover {
    -webkit-filter: drop-shadow(3px 3px 10px var(--secondary-btn--background-color-hover));
    filter: drop-shadow(3px 3px 10px var(--secondary-btn--background-color-hover));
}

.btn-warning:hover {
    -webkit-filter: drop-shadow(3px 3px 10px var(--warning--background-color));
filter: drop-shadow(3px 3px 10px var(--warning--background-color));
}
//end button overide


.bg-primary {
    background: var(--bg-primary);
    box-shadow: 0 0 2px 2px var(--light-border-color);
}
.bg-confirm-yes {
    background: var(--bg-green) !important;
}
.bg-confirm-no {
    background: var(--bg-red) !important;
}//end bg-overide
.bg-red {
    background-color: var(--bg-red);
}
.bg-blue {
    background-color: var(--bg-blue);
}
.bg-secondary {
    background-color: var(--bg-secondary) !important;
}
.border-secondary {
    border-color: var(--second-theme--color) !important;
}