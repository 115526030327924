@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

.mainWrapper {
    /* padding:10px;*/
    padding: 0 20px 0 20px;
    font-family: "Source Sans Pro", sans-serif;
    color: white;
    display: box;
    display: -ms-flexbox;
    display: flex;
    background-color: var(--mainWrapper-background-color);
    flex-flow: column nowrap;

    @include media-min($desktop) {
        flex-flow: row nowrap;
    }
}

.play-layout {
    max-width: 100%;
}

.liveStream {
    box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    position: relative;
}

.liveStream .event {
    margin-bottom: 10px;
}

.liveStream .event .title {
    font-size: 15px;
    font-weight: 700;
    /*color: var(--main-theme--color);*/
    color: var(--livestream-text-color);
    background-color: var( --livestream-background-color);
}

.liveStream .event .date {
    color: var(--livestream-date-text-color);
}

.liveStream .video {
    border-radius: 5px;
    width: 100%;
}

.liveStream .event .titleMessage {
    font-size: 15px;
    font-weight: 700;
    color: var(--bg-red);
}

.play {
    box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 10px 0 0 20px;
    /*    margin-top: 12px;*/
}

.play .bettingStatus {
    background: var(--livestream-betstatus-background-color);
    padding: 0.30px;
    border-radius: 5px;
    text-align: center;
}

.play .currentGame {
    width: 100%;
    border-collapse: collapse;
    /*margin-top: 10px;*/
    background-color: var(--livestream-amount-background-color-hover);
    /*        border-radius: 4px;*/
    border-bottom-left-radius: 5px;
    border-bottom-left-radius: 5px;

    h3 {
        font-size: 22px;
        font-weight: bold;
        font-family: 'Montserrat', sans-serif;
        display: inline-block;
        padding: 3px 1px;
        border-radius: 4px;
    }

    .bg-danger {
        background-color: var(--bg-red) !important;
    }

    .bg-blue {
        background-color: var(--bg-blue) !important;
    }

    .bg-orange {
        background-color: var(--bg-orange) !important;
    }

    .bg-cancel {
        background-color: var(--body-background-color) !important;
    }
}

.play .currentGame th {
    text-transform: uppercase;
}

.play .currentGame td,
.play .currentGame th {
    /* border: 1px solid rgba(255, 255, 255, 0.13);*/
    padding: 4px 12px 12px 12px;
    text-align: center;
    width: 33.3%;
    color: var(--livestream-currentgame-text-color);
}

.play .bettingStatus.active {
    background: var(--bg-green);
}

.play .bets {
    display: box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    margin-top: 10px;
    /*  background-color: #1e2024;*/
}

.play .bets .betMeron {
    line-height: 32px;
    background-color: #111;
    font-family: 'Source Sans Pro', sans-serif;
    /*border: 1px solid var(--bg-green);*/

    .title {
        color: white;
        font-size: 25px;
        padding-bottom: 4px;
    }
}

.play .bets .betMeron .info button:hover {
    background-color: var(--bg-red);
    filter: drop-shadow(3px 3px 10px var(--dark-theme--color));
    // border: 1px solid #F00;
}

.play .bets .betWala {
    background-color: var(--mainWrapper-background-color);
    font-family: 'Source Sans Pro', sans-serif;
    line-height: 32px;
    /* border: 1px solid var(--bg-green);*/
}

.play .bets .betWala .info button {
    border: 1px solid transparent;
    font-weight: 600;

    svg {
        margin-right: 4px;
    }
}

.play .bets .betWala .info button:hover {
    background-color: var(--bg-blue);
    filter: drop-shadow(3px 3px 10px var(--dark-theme--color));
}

.play .bets .betWala .title {
    background: var(--bg-blue);
    color: var(--livestream-betTitle-text-color);
    font-size: 25px;
    padding-bottom: 4px;
}

.play .bets > div {
    width: 100%;
    border: 1px solid var(--livestream-bets-border-color);
    overflow: hidden;
    border-radius: 5px;
}

.play .bets > div .title {
    padding: 8px 0 0;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: var(--livestream-betTitle-text-color);
}

.play .bets > div .info {
    /* padding: 0 10px .30px 10px;*/
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
}


.play .bets > div .info .totalBets {
    color: var(--main-theme--color);
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0px;
}


.play .bets > div .info .payout {
    text-align: center;
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 23px;
}

.play .bets > div .info .currentBet {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 30px;
    color: var(--bg-green);
}

.play .bets > div .info .currentBet span.bet-win {
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    color: var(--main-theme--color);
    padding: 1px;
    border-radius: 4px;
    margin: 0;
    /*background-color:var(--bg-green);*/
}

.play .bets > div .info button {
    padding: 10px 20px;
    background: transparent;
    width: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: black;
    background: var(--primary-btn--background-color);
    font-weight: bold;

    &.closed {
        color: #B8BBBD;
        font-weight: 400;
    }
}

.play .currentPoints {
    margin-top: 20px;
    display: box;
    display: -ms-flexbox;
    display: flex;
    box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}

.play .currentPoints p {
    font-size: 20px;
    font-weight: 700;
    color: var(--main-theme--color);
    font-family: 'Source Code Pro', sans-serif;
}

.play .currentPoints p:nth-child(2) {
    color: var(--main-theme--color);
    font-size: 20px;
    font-weight: bold;
}

.play .currentPoints p:nth-child(2) i {
    padding-right: 0.30px;
}

.play .amount {
    width: 100%;
    display: box;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid var(--livestream-amount-border-color);
    border-radius: 5px;
    margin-top: 30px;
}

.play .amount input[type="text"],
.play .amount input[type="number"] {
    font-weight: 400;
    background: var(--livestream-amount-background-color-hover);
    border: none;
    font-size: 15px;
    padding: 10px;
    border-radius: 3px;
    color: var(--livestream-amount-input-text-color);
    outline: none;
    position: relative;
    resize: none;
    width: 100%;

    &::placeholder {
        color: var(--livestream-amount-input-placeholder-color);
    }
}

.play .amount button {
    padding: 10px 20px;
    /*background: transparent;*/
    width: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--livestream-amount-background-color);
    border-bottom: solid 3px var(--second-theme--color);
}

.play .amount button:hover {
    background: var(--livestream-amount-background-color-hover);
}

.play .amounts {
    display: box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    width: 100%;
    overflow: auto;
    padding: 20px 0;
    box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.play .amountsBet {
    width: 100%;
    overflow: auto;
    padding: 20px 0;
    text-align: center;
}

.play .amountsBet button {
    font-family: 'Montserrat', sans-serif;
    background: var(--livestream-amount-background-color-hover);
    border-bottom: solid 3px var(--second-theme--color);
    border-radius: 7px;
    border-top: unset;
    border-left: unset;
    border-right: unset;
    color: var(--livestream-amount-btn-text-color);
    margin-right: 2px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 20px;
    font-weight: 600;
}

.play .amountsBet button:hover {
    background: var(--livestream-amount-btn-background-color-hover);
    border-bottom: solid 3px var(--light-theme--color);
    border-radius: 7px;
    border-top: unset;
    border-left: unset;
    border-right: unset;
    color: var(--livestream-amount-btn-text-color-hover);
}




.play .amounts button {
    border-radius: 5px;
    /* padding: 10px;*/
    background: var(--livestream-amount-btn-background-color);
    /*       width: fit-content;
        width: -moz-fit-content;*/
    /* width: fit-content;*/
    cursor: pointer;
    border: none;
    color: var(--livestream-amount-btn-text-color);
}

.play .amounts button:hover {
    background: var(--livestream-amount-btn-background-color-hover);
}

.play .betDraw {
    display: box;
    display: -ms-flexbox;
    display: flex;
    box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.play .betDraw button {
    padding: 10px 20px;
    width: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    border: none;
    color: #B8BBBD;
    background: var(--main-theme--color);
    border-radius: 5px;
    box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.play .betDraw button:hover {
    padding: 10px 20px;
    color: var(--main-theme--color);
    width: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    border: none;
    color: white;
    background: transparent;
    border-radius: 5px;
    box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 0px solid var(--main-theme--color);
    outline: 1px solid var(--main-theme--color);
}

.play .betDraw .drawed {
    padding: 10px 20px;
    box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    font-size: 20px;
    font-weight: bolder;
}

.play .betDraw .drawedTotal {
    padding: 10px 20px;
    box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    color: var(--main-theme--color);
    font-weight: bolder;
}

.play .note {
    color: rgba(255, 255, 255, 0.308);
    margin-top: 10px;
    letter-spacing: 1px;
    background-color: var(--livestream-amount-background-color-hover);
    border-radius: 5px;
    padding: 10px 5px;
    font-size: 15px;
    font-weight: 300;

    svg {
        margin-right: 8px;
    }
}

.play .note i {
    margin-right: 0.5rem;
}

.play .results {
    width: 100%;
    margin: 10px 0;
    display: box;
    display: -ms-flexbox;
    display: flex;
    box-orient: vertical;
    box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.play .results .currentBets {
    max-width: 720px;
    overflow-x: auto;
    max-height: 300px;
    margin: 20px 0;
}

.trend-table {
    border-collapse: collapse;
}

.trend-table tr td {
    padding: 2px;
    width: 30px;
    height: 30px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    background: #ffffff21;
    vertical-align: top;
}

.trend-table tr td div {
    width: 30px;
    height: 30px;
    font-size: 15px;
    font-weight: 300;
    line-height: 25px;
}

.trend-table tr td .trend-meron {
    width: 30px;
    height: 30px;
    background-color: #ff0000;
    border-radius: 13px;
}

.trend-table tr td .trend-wala {
    width: 30px;
    height: 30px;
    background-color: var(--bg-blue);
    border-radius: 12px;
}

.trend-table tr td .trend-draw {
    width: 30px;
    height: 30px;
    background-color: var(--main-theme--color);
    border-radius: 12px;
}

.trend-table tr td .trend-cancel {
    width: 30px;
    height: 30px;
    background-color: #9d9d9d;
    border-radius: 12px;
}


.trend-table tr td .circleRedAll {
    width: 30px;
    height: 30px;
    background-color: #F00;
    border-radius: 12px;
    text-align: center;
    color: #fff;
    font-weight: bold;
}

.trend-table tr td .circleBlueAll {
    width: 30px;
    height: 30px;
    background-color: #167df1;
    border-radius: 12px;
    text-align: center;
    color: #fff;
    font-weight: bold;
}

.trend-table tr td .circleGreenAll {
    width: 30px;
    height: 30px;
    background-color: var(--bg-green);
    border-radius: 12px;
    text-align: center;
    color: #fff;
    font-weight: bold;
}

.trend-table tr td .circleCancelAll {
    width: 30px;
    height: 30px;
    background-color: #9d9d9d;
    border-radius: 12px;
    text-align: center;
    color: #fff;
    font-weight: bold;
}


.trend-table tr td:nth-child(even) .circle {
    border: 8px solid #1830b9;
}

.play_resultLabel {
    width: 100%;
    max-width: 600px;
    display: box;
    display: -ms-flexbox;
    display: flex;
    box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    margin: 10px;
}

.play .resultLabel > a {
    background: transparent;
    width: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    border: 1px solid #B49032;
    color: #dadada;
    background-color: #373838;
    border-radius: 5px;
    box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: .10px 10px .10px 10px;
}

.play_resultLabel > div {
    display: box;
    display: -ms-flexbox;
    display: flex;
    box-orient: vertical;
    box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.5rem;
}

.play_resultLabel > div .circle {
    display: box;
    display: -ms-flexbox;
    display: flex;
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 20px;
    box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px;
}

.play_resultLabel > div .circle.meron {
    background: #F00;
    padding: 20px;
}

.play_resultLabel > div .circle.wala {
    background: var(--bg-blue);
    padding: 20px;
}

.play_resultLabel > div .circle.draw {
    background: var(--main-theme--color);
    padding: 20px;
}

.play_resultLabel > div .circle.cancel {
    background: rgba(252, 252, 252, 0.39);
    padding: 20px;
}


.play .results .currentBets {
    max-width: 720px;
    overflow-x: auto;
    max-height: 300px;
    margin: 20px 0;
}

.play .results .currentBets .circle ul li.meron {
    background: var(--livestream-meron-background-color);
    padding: 10px;
}

.play .results .currentBets .circle ul li.wala {
    background: var(--livestream-wala-background-color);
    padding: 10px;
}

.play .results .currentBets .circle ul li.draw {
    background: var(--main-theme--color);
    padding: 10px;
}

.play .results .currentBets .circle ul li.cancel {
    background: var(--bg-cancel);
    padding: 10px;
}

.play .results .result {
    width: 100%;
    overflow: auto;
    max-height: 300px;
    background-color: var(--livestream-background-color);
}

.play .results .result table {
    border-collapse: collapse;
    margin: 10px 0;
    background: var(--livestream-background-color) !important;
}

.play .results .result table tr td {
    padding: 7px;
    border: 1px solid var(--livestream-result-border-colo);
}

.play .results .result table tr td .circle {
    display: box;
    display: -ms-flexbox;
    display: flex;
    height: 1.7rem;
    width: 1.7rem;
    border-radius: 10px;
    box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.play .results .result table tr td .circle.meron {
    background: var(--livestream-meron-background-color);
}

.play .results .result table tr td .circle.wala {
    background: var(--livestream-wala-background-color);
}

.play .results .result table tr td .circle.draw {
    background: var(--livestream-draw-background-color);
}

.play .results .result table tr td .circle.cancelled {
    background: var(--bg-cancel);
}

@media (max-width: 991px) {
    .wrapper {
        // box-orient: vertical;
        // box-direction: normal;
        // -ms-flex-direction: column;
        // flex-direction: column;
    }

    .play {
        padding: 0;
    }

    .play .results .currentBets {
        max-width: 720px;
        overflow-x: auto;
        max-height: 300px;
        margin: 20px 0;
    }
}

@media (max-width: 600px) {
    // .wrapper {
    //     padding: 10px;
    // }

    .play .results .currentBets {
        max-width: 600px;
        ;
        overflow-x: auto;
        max-height: 300px;
        margin: 20px 0;
    }
}

/*# sourceMappingURL=bet.css.map */

.blink_me {
    animation: blinker 2s linear infinite;
}

.text-closed-bet {
    color: var(--livestream-betClosed-text-color);
    font-weight: bold;
    font-size: 15px;
    background-color: var(--bg-red);
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 2px;
}

.text-open-bet {
    color: var(--livestream-betClosed-text-color);
    font-weight: bold;
    font-size: 15px;
    background-color: var(--bg-green);
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 2px;
}

.text-warning-bet {
    color: var(--livestream-betClosed-text-color);
    ;
    font-weight: bold;
    font-size: 15px;
    background-color: var(--warning--background-color);
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 2px;
}

.text-fightnumber {
    color: var(--main-theme--color);
    font-weight: bolder;
    font-size: 20px;
}

.result-table {
    width: 100%;
    overflow-x: scroll;
    background-color: var(--mainWrapper-background-color);
    white-space: nowrap !important;
}

.fixed-div-result-dark {
    width: 100%;
    overflow-x: scroll;
    background-color: var(--mainWrapper-background-color);
    white-space: nowrap !important;
}


button.wala {
    padding: 10px 20px;
    background: transparent;
    cursor: pointer;
    border: none;
    color: white;
    background: var(--livestream-wala-background-color);
    border-radius: 5px;
}

button.meron {
    padding: 10px 20px;
    background: transparent;
    cursor: pointer;
    border: none;
    color: white;
    background: var(--livestream-meron-background-color);
    border-radius: 5px;
}

button.cancel {
    padding: 10px 20px;
    background: transparent;
    cursor: pointer;
    border: none;
    color: white;
    background: var(--bg-cancel);
    border-radius: 5px;
}

button.draw {
    padding: 10px 20px;
    background: transparent;
    cursor: pointer;
    border: none;
    color: white;
    background: var(--bg-green);
    border-radius: 5px;
}

.text-winner-bet {
    color: var(--bg-red);
    font-weight: bold;
    font-size: 15px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 2px;
    display: none;
}

.arena-wrapper {
    width: 100%;
}

.dark-mode .content-wrapper,
.dark-mode,
.dark-mode .site-wrapper,
.dark-mode,
.dark-mode .main-footer {
    background-color: var(--mainWrapper-background-color) !important;
    height: 100%;
}


.el08 {
    /* Change width and height */
    width: 2em;
    height: 2em;
}

.walaresult {
    background-color: var(--livestream-wala-background-color);
    padding: 1px;
    color: #fff;
    border-radius: 2px;
    padding-left: 3px;
    padding-right: 3px;
}

.meronresult {
    background-color: var(--livestream-meron-background-color);
    color: #fff;
    padding: 1px;
    border-radius: 2px;
    padding-left: 3px;
    padding-right: 3px;
}

.drawresult {
    background-color: var(--livestream-draw-background-color);
    color: #fff;
    padding: 1px;
    border-radius: 2px;
    padding-left: 3px;
    padding-right: 3px;
}

.cancelresult {
    background-color: var(--bg-cancel);
    color: #fff;
    padding: 1px;
    border-radius: 2px;
    padding-left: 3px;
    padding-right: 3px;
}

.gameNum input[type="number"] {
    font-weight: 400;
    background: var(--livestream-amount-background-color-hover);
    border: none;
    font-size: 15px;
    padding: 10px;
    border-radius: 3px;
    color: white;
    outline: none;
    position: relative;
    resize: none;
    width: 100%;
}

.play .bettingStatusAlert {
    background: var(--main-theme--color);
    padding: 8px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;

    span {
        color: var(--text-secondary);
    }
}


.titleMenu {
    margin: 0 auto;
    overflow: hidden;
    background-color: var(--bg-dark);
    padding-top: 5px;
    height: 45px;
    display: flex;
    align-items: center;
}


.titleMenu .title {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    padding: 3px;
}

.titleMenu {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.titleMenu div {
    display: block;
    width: 200%;
    height: 30px;
    position: absolute;
    overflow: hidden;
    animation: marquee 16s linear infinite;
    padding-top: 5px;
}

.titleMenu span {
    display: block;
    width: 100%;
    text-align: center;
}

@keyframes marquee {
    0% {
        left: 20%;
    }

    100% {
        left: -120%;
    }
}

.wrapperMenu {
    margin: 0 auto;
    overflow: hidden;
    background-color: var(--livestream-amount-background-color-hover);
    width: 100%;
    padding-top: 5px;
    height: 61px;
}


.menu {
}

.menu ul {
    margin-left: 0;
    list-style: none;
    text-align: center;
}

.menu ul li {
    display: inline-block;
}

.menu ul li a {
    display: block;
    color: var(--menu-link--text-color);
    text-align: center;
    padding: 10px;
    text-decoration: none;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 2px;
}

.menu ul li a:hover {
    color: var(--menu-link--text-color-hover);
    background: var(--menu-link--background-color-hover);
}

.menu ul li a:active {
    background-color: var(--menu-link--background-color-active);
    color: white;
}




.watermark {
    position: absolute;
    left: 3%;
    top: 3%;
    color: white;
    animation: 15s moving-watermark infinite;
}

@keyframes moving-watermark {
    0% {
        left: 3%;
        top: 13%;
    }

    25% {
        left: 93%;
        top: 13%;
    }


    35% {
        left: 33%;
        top: 53%;
    }

    50% {
        left: 93%;
        top: 93%;
    }

    75% {
        left: 13%;
        top: 93%;
    }

    85% {
        left: 53%;
        top: 33%;
    }

    100% {
        left: 13%;
        top: 13%;
    }
}

@media only screen and (min-device-width: 480px) {
    .logo-img {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 991px) {
    .header-mobile__logo-img {
        width: 197px;
        height: auto;
        margin-top: -10px;
    }
}

.form-control {
    color: #fff;
}

.navbar {
    padding: 0rem 10px !important;
}

.nav-link {
    text-transform: uppercase;
    color: #fff !important;
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
    color: var(--main-theme--color) !important;
}

.top-nav {
    font-family: "Montserrat", sans-serif;
}

@media (max-width: 479px) {
    .totalBetsDisplay {
        font-size: 24px !important
    }

    .payoutDisplay {
        font-size: 18px !important
    }

    .fightNoDisplay {
        font-size: 18px
    }

    .my-bets {
        font-size: 14px
    }
}

.label-success {
    background-color: var(--bg-green) !important
}

.statusLabel {
    font-weight: 900 !important;
    font-size: 13px !important;
}

.winner-indicator {
    color: var(--warning--text-color) !important;
    font-weight: bold;
    font-size: 14px
}

.cancel-indicator {
    color: var(--warning--text-color) !important;
    font-weight: bold;
    font-size: 13px;
}

.blinktext {
    animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

#betting-dashboard .label {
    font-size: 10px !important;
}

.bettingStatus {
    padding-top: 11px !important;
}

.betAmount {
    font-weight: bold
}

.userPayoutLabel {
    background-color: var(--bg-blue);
    padding: 0px 2px 0px 2px;
    color: #fff;
    font-size: 13px;
}

#betting-dashboard .beting-status-label {
    font-size: 15px !important;
}

.marquee {
    height: 30px;
    overflow: hidden;
    position: relative;
    background: var(--bg-red);
    color: #fff;
}

.marquee p {
    white-space: nowrap;
    position: absolute;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    height: 100%;
    margin: 0;
    line-height: 30px;
    text-align: center;
    /* Starting position */
    -moz-transform: translateX(100%);
    transform: translateX(100%);
    transform: translateX(100%);
    /* Apply animation to this element */
    -moz-animation: scroll-left 14s linear infinite;
    animation: scroll-left 14s linear infinite;
    animation: scroll-left 14s linear infinite;
}

/* Move it (define the animation) */
@-moz-keyframes scroll-left {
    0% {
        -moz-transform: translateX(100%);
    }

    100% {
        -moz-transform: translateX(-300%);
    }
}

@keyframes scroll-left {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-300%);
    }
}

@keyframes scroll-left {
    0% {
        -moz-transform: translateX(100%);
        /* Browser bug fix */
        transform: translateX(100%);
        /* Browser bug fix */
        transform: translateX(100%);
    }

    100% {
        -moz-transform: translateX(-300%);
        /* Browser bug fix */
        transform: translateX(-300%);
        /* Browser bug fix */
        transform: translateX(-300%);
    }
}

.clock {
    margin: 0 auto;
    padding-top: 5px;
    color: #fff;
    height: 30px !important;
    font-size: 16px;
}

#Date {
    font-family: 'Source Code Pro', Arial, Helvetica, sans-serif;
    font-size: 16px;
    text-align: center;
    text-shadow: 0 0 5px var(--bg-blue);
    font-weight: 300;
}

.clock ul {
    margin: 0 auto;
    padding: 0px;
    list-style: none;
    font-weight: bold;
    text-align: center;
}

.clock ul li {
    display: inline;
    font-size: 16px;
    text-align: center;
    text-shadow: 0 0 5px var(--bg-blue);
    font-weight: 500;
    font-family: 'Source Code Pro', monospace;

    span {
        font-family: 'Source Code Pro', monospace;
    }
}

#point {
    position: relative;
    -moz-animation: mymove 1s ease infinite;
    animation: mymove 1s ease infinite;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 300;
}

/* Simple Animation */
@keyframes mymove {
    0% {
        opacity: 1.0;
        text-shadow: 0 0 20px var(--bg-blue);
    }

    50% {
        opacity: 0;
        text-shadow: none;
    }

    100% {
        opacity: 1.0;
        text-shadow: 0 0 20px var(--bg-blue);
    }
}

@-moz-keyframes mymove {
    0% {
        opacity: 1.0;
        text-shadow: 0 0 20px var(--bg-blue);
    }

    50% {
        opacity: 0;
        text-shadow: none;
    }

    100% {
        opacity: 1.0;
        text-shadow: 0 0 20px var(--bg-blue);
    }

    ;
}

.iframeWrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.iframeWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.betPopup {
    position: fixed;
    height: auto;
    width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%,);
    z-index: 9999;

    &__wrapper {
        background-color: var(--betPopup-wrapper--background-color);
        padding: 1px;
    }

    &__header {
        background-color: var(--betPopup-header--background-color);
        color: var(--betPopup-header--text-color);
        padding: 2px 2px 2px 8px;
        font-size: 16px;
        width: 100%;
        display: block;
        font-weight: bold;
    }

    &__content {
        padding: 10px;
        color: var(--betPopup-content--text-color);
    }

    &__button {
        display: inline-flex;
        align-content: center;
        background: var(--bg-green);
        border: 1px solid var(--secondary-btn--border-color);
        color: var(--secondary-btn--text-color);
        font-weight: bold;
        margin: 20px 10px 10px 10px;
        padding: 5px 32px;

        &:hover {
            color: var(--secondary-btn--text-color-hover);
            background: var(--secondary-btn--background-color-hover);
            border: 1px solid var(--secondary-btn--border-color-hover);
        }
    }
}

.play-card {
    background-color: var(--playcard--background-color);
}

.play-card .card-header, .play-card .card-footer {
    background: var(--playcard-header--background-color);
    font-size: 16px;
    font-weight: bold;
    color: var(--carHead--text-color);
    border: solid 2px var(--second-theme--color);
}

@keyframes steam {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}


.c-play-poolbets {
    border-radius: 8px;
    padding-bottom: 16px;
    position: relative;
	background: linear-gradient(0deg, #000, #272727);

    &:before, &:after {
        content: '';
        position: absolute;
        left: -2px;
        top: -2px;
        background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
            #0000ff, #00ff00,#ffff00, #ff0000);
        background-size: 400%;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        z-index: -1;
        animation: steam 20s linear infinite;
    }

    &:after {
        filter: blur(50px);
    }

    &__header {
        background: var(--tableHead--background-color);
        border-radius: 5px;
        padding: 8px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        font-size: 18px;

        @include media-min($desktop) {
            padding: 12px 24px;
            margin-bottom: 16px;
        }

        img {
            height: 30px;

            @include media-min($desktop) {
                height: 50px;
            }
        }

        .right {
            animation-duration: 600ms;
            animation-name: colorchange;
            animation-iteration-count: infinite;
            animation-direction: forwards;
            white-space: nowrap;
        }

        .btn {
            display: inline-block;
            padding: 2px;
            font-size: 12px;
            color: white;
            margin: 0 4px;
            font-weight: bold;
            border-radius: 2px;

            @include media-min($desktop) {
                padding: 8px;
                font-size: 14px;
                margin: 0 8px;
                border-radius: 5px;
            }

            &--high {
                background-color: var(--bg-green);
            }

            &--low {
                background-color: var(--bg-red);
            }
        }
    }

    &__subheader {
        padding: 6px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        height: 52px;

        @include media-min($desktop) {
            padding: 12px 24px;
            height: 64px;
        }

        .control {
            display: flex;
            gap: 4px;

            @include media-min($desktop) {
                flex-flow: row nowrap;
            }

            .btn {
                padding: 2px 4px;
                font-size: 12px;
                font-family: "Rubik", serif;
                font-weight: bold;
                color: white;
                display: inline-block;
                position: relative;

                @include media-min($desktop) {
                    font-size: 16px;
                    padding: 2px 8px;
                }

                &--open {
                    background-color: var(--bg-blue);
                }

                &--close {
                    background-color: var(--bg-red);
                }

                &--last {
                    border: 1px white solid;
                    border-radius: 10px;
                }

                &--win {
                    background: var(--primary-btn--background-color);
                }

                &--is-active::before {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    background-color: lightyellow;
                    left: 0;
                    top: 0;
                    filter: blur(10px);
                    z-index: 0;
                }

                &:not(:last-child) {
                    margin-right: 12px;
                }
            }
        }
    }

    &__body {
        padding: 0 20px 20px;

        .text-label {
            font-size: 18px;
            font-family: "Montserrat", sans-serif;
            font-weight: bold;
        }

        .prize-pool {

            &--low {
                color: var(--bg-red);
                ;
            }

            &--high {
                color: var(--bg-blue);
            }
        }

        .arrow {
            padding: 5px 10px;
            border-radius: 5px;

            &--low {
                background-color: var(--bg-red);
            }

            &--high {
                background-color: var(--bg-blue);
            }
        }
    }

    .chart-time {
        font-size: 14px;
        color: #9d9d9d;
        margin-top: 15px;
    }

    .timer-clock {
        background-color: white;
        border: #111 1px outset;
        font-size: 38px;
        color: black;
        border-radius: 10px;
        display: inline-flex;
        align-items: center;
        padding: 2px 8px;
        line-height: 34px;

        span {
            font-size: 28px;
        }
    }

    .last-call-text {
        height: 24px;
    }

    .place-bets {

        span {
            animation: glowing 3s linear infinite;
            color: #9d9d9d;
        }

        span:nth-child(1){
            animation-delay: 0s;
        }

        span:nth-child(2){
            animation-delay: .2s;
        }

        span:nth-child(3){
            animation-delay: 0.4s;
        }

        span:nth-child(4){
            animation-delay: 0.6s;
        }

        span:nth-child(5){
            animation-delay: 0.8s;
        }

        span:nth-child(6){
            animation-delay: 1s;
        }

        span:nth-child(7){
            animation-delay: 1.2s;
        }

        span:nth-child(8){
            animation-delay: 1.4s;
        }

        span:nth-child(9){
            animation-delay: 1.6s;
        }

        span:nth-child(10){
            animation-delay: 1.8s;
        }

        span:nth-child(11){
            animation-delay: 2s;
        }

        span:nth-child(12){
            animation-delay: 2.2s;
        }

        span:nth-child(13){
            animation-delay: 2.4s;
        }

        span:nth-child(14){
            animation-delay: 2.6s;
        }

        span:nth-child(15){
            animation-delay: 2.8s;
        }
    }
}

.chart-container {
    height: 240px;

    @include media-min(414px) {
        height: 250px;
    }

    @include media-min(490px) {
        height: 290px;
    }

    @include media-min(540px) {
        height: 320px;
    }

    @include media-min($tablet) {
        height: 420px;
    }

    @include media-min(1024px) {
        height: 290px;
    }

    @include media-min($desktop) {
        height: 360px;
    }

    @include media-min(1510px) {
        height: 420px;
    }

    @include media-min(1700px) {
        height: 480px;
    }

    @include media-min(2050px) {
        height: 520px;
    }
}

.reactchart-container {
    max-height: 400px;
    overflow-y: auto;
}

@keyframes glowing {
    0%, 100% {
        color: #fff;
        filter: blur(2px);
        text-shadow: 0 0 10px #FFFFFF, 0 0 20px #FFFFFF, 0 0 40px #FFFFFF, 0 0 80px #FFFFFF, 0 0 120px #FFFFFF, 0 0 200px #FFFFFF, 0 0 300px #FFFFFF, 0 0 400px #FFFFFF;
    }

    5%, 95% {
        color: #9d9d9d;
        text-shadow: none;
        filter: blur(0px);
    }
}

.pop-outin {
    animation: 1.6s anim-popoutin ease infinite;
}
  
@keyframes anim-popoutin {
    0% {
      color: white;
      transform: scale(0);
      opacity: 0;
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    25% {
      color: var(--main-theme--color);
      transform: scale(1.5);
      opacity: 1;
      text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
    }
    50% {
      color: white;
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      /* animate nothing to add pause at the end of animation */
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes fiery-path {
    0% {
        background-position: 0% 0%;
    }

    100% {
        background-position: 400% 400%;
    }
}

@keyframes flicker {
    0%, 100% {
        opacity: 1;
    }

    50% {
        opacity: 0.6;
    }
}

.c-loading-data {
    margin: 0 auto;
    padding: 32px;
    display: flex;
    justify-content: center;

    div {
        width: 1rem;
        height: 1rem;
        margin: 2rem 0.3rem;
        background: var(--second-theme--color);
        border-radius: 50%;
        animation: 0.7s bounce infinite alternate;

        &:nth-child(2) {
            animation-delay: 0.3s;
        }

        &:nth-child(3) {
            animation-delay: 0.6s;
        }
    }
}

@keyframes colorchange {
    0% {
        color: white;
    }

    100% {
        color: red
    }
}

@keyframes bounce {
    to {
        opacity: 0.3;
        transform: translate3d(0, -1rem, 0);
    }
}
