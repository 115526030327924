.table-responsive {
    box-shadow: 0 0 12px 2px var(--table-shadow--color);
    padding: 0;
    margin-bottom: 20px;
    border: 2px solid var(--second-theme--color);

    table {
      margin: 0;

      thead {
        border-bottom: 2px solid var(--second-theme--color);
        
        th {
          padding: 16px 0;
        }
      }
    }
  }

.table-responsive table thead th {
    background: var(--tableHead--background-color);
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: var(--table-row-odd--background-color);
}

.table-striped>tbody>tr:nth-of-type(even)>* {
    background-color: var(--table-row-even--background-color);
}