$main-text-size: 16px;
$main-border-radius: 6px;

$small-space: 16px;
$medium-space: 32px;
$large-space: 64px;

$phone-size: 600px;
$tablet-size: 992px;
$desktop-size: 1200px;

$mobile: 320px;
$tablet: 768px;
$tablet-sm: 991px;
$desktop: 1240px;

$breakpoints: (
    mobile: $mobile,
    tablet: $tablet,
    desktop: $desktop
);

@mixin flexbox($justify, $align, $direction, $wrap) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
  flex-wrap: $wrap;
}
@mixin rectangle($width, $height) {
  width: $width;
  height: $height;
}
@mixin square($size) {
  @include rectangle($size, $size);
}
@mixin breakpoint($class) {
  @if $class == '<phone' {
    @media (max-width: $phone-size) {
      @content;
    }
  }

  @if $class == '<tablet' {
    @media (max-width: $tablet-size) {
      @content;
    }
  }

  @if $class == '<desktop' {
    @media (max-width: $desktop-size) {
      @content;
    }
  }

  @if $class == '>desktop' {
    @media (min-width: $desktop-size) {
      @content;
    }
  }
}
@mixin media-min($size) {
  @media (min-width: $size) {
    @content;
  }
}
@mixin media-max($size) {
  @media (max-width: $size) {
    @content;
  }
}